Cookies.set('storeName', process.env.MIX_FREECME_MAGENTO_STORE_NAME || 'free_cme_default_view')

import '../../../global.d.ts'
import { createApp } from 'vue'
import { store, key } from './store'
import { loginWithJWTToken } from '../../global/js/magento-api-library/auth/login'

import Cookies from 'js-cookie'
import SearchResults from './modules/search/SearchResults.vue'
import Search from './modules/search/Search.vue'
import Register from './modules/user/Register.vue'
import Login from './modules/user/Login.vue'
import Profile from './modules/user/Profile.vue'
import Dashboard from './modules/user/Dashboard.vue'
import Accordion from './modules/accordion/Accordion.vue'
import AccordionSimple from './modules/accordion/AccordionSimple.vue'
import Panel from './modules/accordion/Panel.vue'
import HeaderContentWrapper from './modules/layout/HeaderContentWrapper.vue'
import HeaderLogin from './modules/login/HeaderLogin.vue'
import Pagination from './modules/search/Pagination.vue'
import DynamicSelect from './modules/form/DynamicSelect.vue'
import DropDownComponent from './modules/form/DropDownComponent.vue'
import LoginLogoutLinks from './modules/login/LoginLogoutLinks.vue'
import Loading from './modules/loaders/Loading.vue'
import LoginForm from './modules/user/LoginForm.vue'
import RetrievePassword from './modules/user/RetrievePassword.vue'
import ContactSupportForm from './modules/miscForms/ContactSupportForm.vue'
import ResetPasswordForm from './modules/user/ResetPasswordForm.vue'
import ResetPassword from './modules/user/ResetPassword.vue'
import PersonalHero from './modules/user/PersonalHero.vue'
import Productcard from './modules/product/ProductCard.vue'
import Pdp from './modules/product/ProductDetailsPage.vue'
import Wishlist from './modules/product/Wishlist.vue'
import MobileNav from './modules/navigation/MobileNav.vue'
import Styleguide from './modules/styleguide/Events.vue'
import CmsBlockCreateAccount from './modules/cms/CmsBlockCreateAccount.vue'
import CmsBlockSeriesBanner from './modules/cms/CmsBlockSeriesBanner.vue'
import SsoRedirectLink from './modules/navigation/SsoRedirectLink.vue'
import CookiePolicy from './modules/user/CookiePolicy.vue'
import Breadcrumbs from './modules/carousels/Breadcrumbs.vue'
import AccountLinks from './modules/login/AccountLinks.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import {
  faCaretRight,
  faCaretDown,
  faAngleDown,
  faChevronRight,
  faChevronDown,
  faPlus,
  faMinus,
  faInfoCircle,
  faSearch,
  faBars,
  faAddressCard,
  faExclamationCircle,
  faPlusCircle,
  faCamera,
  faTimes,
  faTrash,
  faArrowRight,
  faColumns,
  faBook,
  faAward } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import '../../global/js/ts-validators'
import '../css/main.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue3Recaptcha from 'vue3-recaptcha2'

library.add(
  faFacebookF,
  faCaretRight,
  faCaretDown,
  faAngleDown,
  faChevronRight,
  faChevronDown,
  faPlus,
  faMinus,
  faInfoCircle,
  faSearch,
  faBars,
  faAddressCard,
  faUserCircle,
  faCheckCircle,
  faExclamationCircle,
  faPlusCircle,
  faCamera,
  faTimes,
  faTrash,
  faArrowRight,
  faColumns,
  faBook,
  faAward)

const app = createApp({
  components: {
    DropDownComponent,
    DynamicSelect,
    SearchResults,
    Search,
    Login,
    Profile,
    Dashboard,
    Register,
    Accordion,
    AccordionSimple,
    Panel,
    HeaderContentWrapper,
    HeaderLogin,
    Pagination,
    LoginLogoutLinks,
    Loading,
    LoginForm,
    RetrievePassword,
    ContactSupportForm,
    ResetPasswordForm,
    ResetPassword,
    PersonalHero,
    Productcard,
    Pdp,
    Wishlist,
    MobileNav,
    CmsBlockCreateAccount,
    CmsBlockSeriesBanner,
    Styleguide,
    SsoRedirectLink,
    CookiePolicy,
    FontAwesomeIcon,
    Breadcrumbs,
    AccountLinks,
  },
  async beforeCreate(){
    const searchQuery = new URLSearchParams(location.search)
    const token = searchQuery.get('authToken')
    if(token){
      if (await loginWithJWTToken(token)) {
        window.location.href = window.location.pathname + window.location.hash
      }
    }
  },
})

app.use(store, key)

// Recaptcha 3
app.use(VueReCaptcha, { siteKey: process.env.MIX_RECAPTCHA_KEY })

// Recaptcha 2 (fallback)
app.use(Vue3Recaptcha, { siteKey: process.env.MIX_RECAPTCHA_V2_KEY })

app.mount('#app')
