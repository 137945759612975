interface useProductFlag {
  formatDate: (time:number|string) => string,
  checkExpiration: (expires:number|string) => boolean,
}

const useProductFlags = (): useProductFlag => {
  /**
   * Formats the time to [Mon XX XXXX] (Month Day Year)
   * 
   * New epoch requires you to multiply the epoch time by 1000 to work with the Data javascript library
   * https://stackoverflow.com/a/12342913
   */
  const formatDate = (time: number | string): string => {

    let timeVal = time

    if (typeof timeVal === 'string'){
      timeVal = convertToUnixTimestamp(timeVal)
    }

    if (timeVal != null && !isNaN(timeVal)) {
      const formattedDate = new Date(timeVal * 1000)
  
      return formattedDate.toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric'})
    } else {
      return ''
    }
  }

  /**
   * @TODO Description
   */
  const checkExpiration = (expires: number | string): boolean => {
    let expiresVal = expires

    if (typeof expiresVal === 'string'){
      expiresVal = parseInt(expiresVal)
    }

    if (expiresVal != null) {
      const expirationDate = new Date(expiresVal * 1000)
      expirationDate.setMonth(expirationDate.getMonth() - 1)
      const today = new Date()
  
      const diff = today.getTime() - expirationDate.getTime()
      return diff >= 0
    } else {
      return false
    }
  }

  /**
   * Converts time to a unix timestamp in seconds
   */
  const convertToUnixTimestamp = (time: string): number => {
    return Math.floor(new Date(time).getTime() / 1000)
  }

  return {
    formatDate,
    checkExpiration,
  }
}

export default useProductFlags